.competitionheading{
    text-align: center;
    background-color:rgba(253, 212, 162, 0.881);
    margin: auto;
    width: fit-content;
    padding: 10px;

}
.compdesc{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    color: black;

}
.compdesc h3{
    /* color: rgb(60, 59, 59); */
    font-family: unset;
    font-size: 30px;
}
.compdescright{
    width: 35%;
    font-weight: bold;
    font-size: 20px;
    /* font-family: Arial, Helvetica, sans-serif; */
    font-family: 'Roboto Slab', serif;
}
.compdescleft{
    width: 35%;
    font-weight: bold;
    font-size: 20px;
    /* font-family: Arial, Helvetica, sans-serif */
    font-family: 'Roboto Slab', serif;
}
.compregister button{
    border-radius: 10px;
    width: 150px;
}
.compregister{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
}
.compdescright h3{
    background-color:rgba(253, 212, 162, 0.881);
    width: fit-content;
    padding: 0 20px;
}
.compimpnote{
    text-align: center;
    background-color: #404040;
    color: white;
    margin: auto;
    width: fit-content;
    padding: 20px;
}
.compimpnote p{
    margin: 0;
}
.compimages{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 50px;
    margin: 40px 0;
}
.compimages img{
    width: 20%;
}
.judgeposters{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 70px;
    margin: 50px 0;
    padding: 20px;
}
.judgeposters img{
    width: 19%;
}
.judgeheading{
    text-align: center;
    background-color:rgba(253, 212, 162, 0.881);
    margin: auto;
    width: fit-content;
    padding: 10px;
    margin-top: 100px;

}


.result-div{
    text-align: center;
}


.result-button{
        padding: 15px 20px;
        background-color: #e75917;
        color: white;
        border: none;
        border-radius: 5px;
        font-size: 20px;
        cursor: pointer;
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
      
}

.result-button:hover {
  background-color: #3e8e41;
}


@media screen and (max-width: 800px){
    .compdesc{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }
    .compdescleft{
        width: 90%
    }
    .compdescright{
        width: 90%;
    }
    .compimages{
        flex-direction: column;
        align-items: center;
    }
    .compimages img{
        width: 80%
    }
    .judgeposters{
        flex-direction: column;
        align-items: center;
        padding: 0;
    }
    .judgeposters img{
        width: 80%;
    }
}