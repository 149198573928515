.volunteerlist{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    padding: 3% 5% 3% 10%;
    margin: auto;
    gap: 50px;
    margin-bottom: 100px;
    font-family: 'Playfair Display', serif;
  }
  .Directorrow , .volrow{
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    gap: 50px;
  }
  .eachvol{
    width: 320px;
    height: 510px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    background-color: rgb(205, 204, 204);
    border-radius: 12px;
    transition: 0.5s;
    position: relative;
    z-index: 0;
  }
  .voldescr{
    display: flex;
    
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
  .voldescr h2{
    text-align: center;
    width: 100%;
  }
  .eachvol h2{
    background-color: whitesmoke;
    color: rgba(193, 10, 193, 0.804);
    text-align: center;
    font-weight: bolder;
  }
  .eachvol h1{
    /* background-color: whitesmoke; */
    font-size:x-large;
    padding-left: 20px;
    padding-top: 10px;
    height: 20%;
  }
  .eachvol p{
    /* background-color: whitesmoke; */
    padding-left: 20px;
  } 
.eachvol img{
    width: 100%;
    height: 60%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.eachvol:hover{
     /* height: 320px; */
     transform: translateY(18px);
     transform: scale(1.02);
     object-fit: contain;
     box-shadow: 2px 5px 5px rgb(99, 97, 97);
}

@media screen and (max-width: 800px){
  .volunteerlist{
    padding: 5%;
  }
  .Directorrow , .volrow{
    flex-direction: column;
  }
  .eachvol{
      flex-direction: column;
      align-items: center;
      width: 80%;
      margin: 5%;

      /* height: 500px; */
  }
  
}

@media screen and (max-width: 500px){
  
}