.main{
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    margin-top: 10%;
}

.main h1{
    color:orange
}
.main h3{
    margin-bottom: 5%;
}

.main p{
    text-decoration: underline;
    color: orange;
    cursor: pointer;
}
.back{
    text-decoration: underline;
    max-width: 10%;
}