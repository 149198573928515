.shikright{
    display: flex;
    flex-direction: column;
    width: 30%;
}
.eventDetails{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
}
.innereventsdetails{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 80px;
    align-items: center;
    background-color: aliceblue;
    padding: 10px;
    border-radius: 10px;
}
.innereventsdetails div{
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 90%;
    cursor: pointer;
}
.innereventsdetails div div{
    display: flex;
    gap: 0;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}
.innereventsdetails div div p{
    margin: 0;
}
.innereventsdetails img{
    width: 30%;
    border-radius: 10px;
    height: 100%;
    object-fit: cover;
}
b:hover{
    color:#e18c1c
}
.eventJoin{
    display: flex;
    color: white;
    flex-direction: column;
    padding: 30px;
    text-align: center;
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('../img/Picture16.jpg');
    background-size: cover;
    width: 80%;
    border-radius: 10px;
}
.eventJoin button{
    width: 40%;
    padding: 2%;
    background-color: orange;
    border-radius: 10px;
}
.eventJoin button:hover{
    background-color: #e18c1c;
}
.eventJoin h2{
    color: white;
}

.innereventsdetails a{
    text-decoration: none;
    color:black;
}
.innereventsdetails a:hover{
    color:black;
}
.innereventsdetails b:hover{
    color:#e18c1c;
}