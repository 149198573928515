.slider {
    position: relative;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
  }
  
  .image {
    width: 90%;
    height: auto;
    border-radius: 10px;
    display: block;
    margin: auto auto;
    /* margin-bottom: -100px; */
  }
  
  .right-arrow {
    position: absolute;
    top: 50%;
    right: 3%;
    font-size: 1.5rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  
  .left-arrow {
    position: absolute;
    top: 50%;
    left: 3%;
    font-size: 1.5rem;
    color: #000;
    z-index: 10;
    cursor: pointer;
    user-select: none;
  }
  
  /* .slide {
    opacity: 0;
    transition-duration: 1s ease;
  } */
  
  .slide.active {
    opacity: 1;
    transition-duration: 1s;
    transform: scale(1.08);
  }
  
  @media (max-width: 800px) {
    .slider {
      height: auto;
      margin-top: 40px;
      margin-bottom: -40px;
    }
  
    .image {
      width: 100%;
      height: auto;
      display: block;
      margin: auto auto;
    }
  
    .right-arrow,
    .left-arrow {
      font-size: 1rem;
    }
  }
  
