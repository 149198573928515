.main{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:2rem;
}

.main p{
    font-size: larger;
    font-weight: 500;
}

@media screen and (max-width: 950px){
    .main img{
        
        width: 50%;
    }
    .main{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap:2rem;
        text-align: center;
    }
    
}