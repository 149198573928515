.year-btn {
  font-family: Poppins, sans-serif;
  font-weight: 600;
  font-size: 16px;
  padding: 1px 10px;
  cursor: pointer;
  height: 25px;
  display: inline-block;
}

.year-btn:after {
  display: block;
  content: '';
  border-bottom: 2px solid #f56631;
  transform: scaleX(0);
  transition: transform .3s ease-in-out;
  transform-origin: 0% 100%;
}

.year-btn:hover {
  color: #f56631;
}

.year-btn:hover:after {
  transform: scaleX(1);
  transform-origin: 0% 100%;
}

.active-btn-style {
  color: #f56631;
}

.filter-btn {
  display: flex;
  width: 400px;
  margin: auto;
  justify-content: space-between;
  align-items: center;
}



@media screen and (max-width: 475px) {
  .filter-btn {
    width: 90%;
  }

  .year-btn {
    font-size: 14px;
    padding: 1px 5px;
  }
}