p{
  /* font-family: cursive; */
  font-family: 'Roboto Slab', serif;
}
html{
  width: 100%;
  min-height: auto;
  overflow-x: hidden;
}
body {
  margin:0;
  padding:0;
  overflow-x:hidden;
  background-color: rgb(228, 220, 220) !important;
}
.App {
  text-align: center;
  max-width: 4137px;
}
