.whats-app {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 15px;
    background-color: #1ea04d;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.whats-app:hover {
    color: white
}


.my-float {
    margin-top: 16px;
}