.RecentEvents{
    height: 25%;
    justify-content: space-around;
   text-align: center;
    margin-bottom: 100px;
}

.RecentEvents h3{
    color: #ffa026;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 2rem;
    margin-bottom: 2%;
}
.RecentEvents h1{
    margin-bottom: 2%;
}

.RecentEvents button{
    border-radius: 20px;
    background-color: #ffa026;
    color: white;
    border: solid 1px #f9aa44;
    padding: 0.5rem 0.5rem;
    margin-bottom: 1rem;
    text-align: center;
    
}
.RecentEvent{
    /* text-align: left; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 3%;;
    gap:2%;
    
}
.RecentEvent img{
    max-width: 90%;
    max-height: 80%;
    border-radius: 5%;
    opacity: 0;
}
.RecentEvent div{
    width: 30%;
   
    border-radius: 20px;
    text-align: center;
    color: white;
    height:250px;
    
}

.RecentEvent div h3{

    color: orange;
    margin:0;
    margin-left: 5%;
    margin-bottom: 2%;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}
.RecentEvent p{
    color:white;
    text-align: center;
}
.RecentEvent div button{
    background-color: orange;
    border:solid 3px #ffa026;
    margin-left: 20%;
    width:90%;
}
.RecentEvent div button:hover{
    background-color:#ffa026;
    transition: 0.5s;
}
.RecentEvent div div{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
#viewAllProg{
    background-color: #ffa026;
    border-radius: 25px;
    font-size: 1rem;
    margin: 0.5rem 0 0.5rem;
    color: white;
    border: #ffa026;
    padding: 0.7rem 1rem;
    font-weight: bold;
}
@media screen and (max-width: 800px){
    .RecentEvent{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 0;
        gap: 30px;
    }
    .RecentEvent div{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        width: 80%;
        gap: 10px;
    }
    .RecentEvent div button{
        width: 40%;
        margin: 0 0 10px 0;
    }
    .RecentEvent div h3{
        margin: 0;
    }
    #viewAllProg{
        margin-top:  20px;
    }
}