.aimbox{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap : 10px;
    height: 180px;
    margin-bottom: 7%;
    max-width: 1500px;
    margin: auto;
}
.aimbox div{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    width: 18%;
    text-align: center;
    /* border: solid 1px black; */
    border-radius: 15px;
    background-color: #F1F7F3;
}
.aimbox h3{
    color: black;
}
.aimbox i{
    /* display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; */
    width: 70px;
    margin-top: 10px;
    margin-left: 35%;
    font-size: 200%;
    border: solid 1px #FFA026;
    color: #FFA026;
    border-radius: 50px;
    padding: 14px 0;
}
@media screen and (max-width: 850px){
    .aimbox{
        display: flex;
        flex-direction: column;
        height: 60%;
        justify-content: center;
        align-items: center;
    }
    .aimbox i{
        font-size: 150%;
        width: 60px;
    margin-left: auto;
    margin-right: auto;
    }
    .aimbox div{
        width: 50%;
        height: 5;
    }
}