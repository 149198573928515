@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');


#gallery-heading{
  position: absolute;
  top: 552px;
  left: 7%;
  background-color: #f4efef;
  width: 93%;
  height: 100px;
  display: flex;
  padding-left: 30px;
  align-items: center;
}

#gallery-heading-text {
  text-align: center;
  font-size: 40px;
  color: rgb(245, 102, 49);
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
}

.gallery-header {
  background-color: #FFB84C;
  display: flex;
  margin-bottom: 120px;
  transform-origin: inherit;
  height: 500px;
  background-repeat: no-repeat;
  background-size: cover;
}

.container {
  width: 95%;
  margin: 20px auto 0 auto;
  display: block;
  padding: 20px 20px 100px 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
  overflow-y: hidden;
}

.gallery-img {
  width: 100%;
  height: 330px;
  vertical-align: middle;
  box-shadow: 2px 2px 8px #b1b0b0;
}

.gallery-img:hover {
  filter: opacity(60%);
}




@media screen and (max-width: 850px) {
  .container {
    grid-template-columns: repeat(2, 1fr);
  }

  .gallery-img {
    height: 260px;
  }
}

@media screen and (max-width: 475px) {
  .container {
    grid-template-columns: 1fr;
  }

  .gallery-img {
    height: 280px;
  }

  #gallery-heading-text {
    font-size: 30px;
  }
}