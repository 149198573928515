.fundRaising2{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 0%;
}
.fundRaising3{
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    justify-content: center;
    background-repeat: no-repeat;
    
    background-size: cover;
    margin-bottom: 0%;
    gap: 7%;
}
.fundRaising3 .fundLeft2{
    margin: 0%;
}
.fundRaising3 .fundRight2{
    margin: 0; 
}
.fundLeft2{
    margin: 5% 5% 0% 5%;
    color: black;
    width: 50%;
}
.fundLeft2 button{
    padding: 10px;
    border-radius: 20px;
    font-size: 20px;
}
.fundRight2{
    margin: 5% 8% 5% 0; 
    padding: 2%;
    border-radius: 20px;
    color: #FFA026;
    background-color: white;
    width: 25%;
    height: 25%;
    text-align: center;
}
.fundRight2 img{
    width: 100%;
    height: 300px;
    object-fit: cover;
}
.fundLeft2 h5{
    font-size: 150%;
    color: #ffa026;
}
.fundLeft2 p{
    font-size: 120%;
}
.fundLeft2 h1{
    font-family: none;
    font-size: 300%;
    color: black;
}
.playButton{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
 }
.playButton i{
    font-size: 300%;
    background-color: #FFA026;
    border-radius: 100px;
    padding: 1px 0 0 0;
    border: solid 1px #FFA026;
    transition: 0.3s;

}
.playButton i:hover{
    color: #FFA026;
    background-color: aliceblue;
    border: solid 1px #FFA026;

}

.rednote{
    background-color: red;
    width: 100%;
    text-align: center;
    color: white;
    font-size: x-large;
    margin-top: 50px;
    text-decoration: none;
}
@media screen and (max-width: 800px){
    .fundRaising2{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
    .fundRaising3{
        flex-direction: column;
        gap: 20px;
        justify-content: center;
        align-items: center;
        margin-right: 0;

    }
    .fundRight2{
        width: 60%;
        margin: 2% 0;
    }
    .fundRight2 img{
        width: 100%;
    }
    .fundLeft2{
        width: 90%;
    }
    .fundLeft2 h1{
        font-size: 150%;
    }
    .rednote{
        font-size: large;
    }
}
@media screen and (max-width: 450px){
    .fundRight2{
        width: 80%;
        margin: 2% 0;
    }
    .rednote{
        font-size: medium;
    }
}